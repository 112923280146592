import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  get buttonLabel() {
    return this.element.querySelector('.mdc-button__label').textContent;
  }

  get apiUrl() {
    return document.querySelector('body').dataset.buttonClickApiUrl;
  }

  get pageName() {
    return document.querySelector('body').dataset.pageName;
  }

  connect() {
    this.element.addEventListener('click', () => {
      const formData = new FormData();
      formData.append('button_click[button_label]', this.buttonLabel);
      formData.append('button_click[page_name]', this.pageName);
      Rails.ajax({
        url: this.apiUrl,
        data: formData,
        type: 'post',
        dataType: 'json'
      });
    });
  }
}
